import { coreClient as client } from '../../helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '../../helpers/requests/bennieFetch';

export const fetchSavedFacilities = async () => {
  try {
    const response = await client.get(`/api/ui-services/facilities`, {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    });
    if (responseFailed(response.status)) {
      throw new Error(response.statusText || 'Error fetching saved facilities');
    }
    return getResponseData(response);
  } catch (e) {
    throw new Error(e);
  }
};

export const saveFacility = async facility => {
  const response = await client.post(
    `/api/ui-services/facilities`,
    { ...facility },
    {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    },
  );

  if (responseFailed(response.status)) {
    throw new Error(response.statusText || 'Error saving provider');
  }

  return getResponseData(response);
};
