import { put, takeLatest } from 'redux-saga/effects';

import * as adminApi from '../../../api/admin';
import {
  fetchMfaConfigError,
  fetchMfaConfigSuccess,
  fetchMfaConfigRequest,
} from './index';

function* fetchMFAConfig({ payload: companyId }) {
  try {
    const response = yield adminApi.getMFAConfig(companyId);

    yield put(fetchMfaConfigSuccess({ enabled: response }));
  } catch (error) {
    yield put(fetchMfaConfigError(error));
  }
}

export function* adminFetchMFAConfigWatcher() {
  yield takeLatest(fetchMfaConfigRequest, fetchMFAConfig);
}
