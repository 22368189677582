import { put, takeLatest } from 'redux-saga/effects';

import * as adminApi from '../../../api/admin';
import {
  createSsoConfigError,
  createSsoConfigSuccess,
  fetchSsoConfigError,
  fetchSsoConfigSuccess,
  deleteSsoConfigError,
  deleteSsoConfigSuccess,
  enableSsoConfigSuccess,
  enableSsoConfigError,
  fetchSsoConfigRequest,
  createSsoConfigRequest,
  deleteSsoConfigRequest,
  enableSsoConfigRequest,
} from './index';

function* fetchSSOConfig() {
  try {
    const response = yield adminApi.getSSOConfig();

    yield put(fetchSsoConfigSuccess({ response }));
  } catch (error) {
    yield put(fetchSsoConfigError({ error }));
  }
}

function* createSSOConfig({ payload }) {
  try {
    const response = yield adminApi.createSSOConfig(payload);

    const { idp } = payload;

    yield put(
      createSsoConfigSuccess({
        response: { idp, enabled: false, ...response },
      }),
    );
  } catch (error) {
    yield put(createSsoConfigError({ error }));
  }
}

function* deleteSSOConfig() {
  try {
    yield adminApi.deleteSSOConfig();

    yield put(deleteSsoConfigSuccess());
  } catch (error) {
    yield put(deleteSsoConfigError({ error }));
  }
}

function* enableSSOConfig() {
  try {
    const response = yield adminApi.enableSSOConfig();

    yield put(enableSsoConfigSuccess({ response }));
  } catch (error) {
    yield put(enableSsoConfigError({ error }));
  }
}

export function* adminFetchSSOConfigWatcher() {
  yield takeLatest(fetchSsoConfigRequest, fetchSSOConfig);
}

export function* adminCreateSSOConfigWatcher() {
  yield takeLatest(createSsoConfigRequest, createSSOConfig);
}

export function* adminDeleteSSOConfigWatcher() {
  yield takeLatest(deleteSsoConfigRequest, deleteSSOConfig);
}

export function* adminEnableSSOConfigWatcher() {
  yield takeLatest(enableSsoConfigRequest, enableSSOConfig);
}
