import { createSlice } from '@reduxjs/toolkit';

import { setState } from '../../../helpers/store';

const mfaSlice = createSlice({
  name: 'mfa',
  initialState: {
    isFetchingConfig: false,
    isEnablingConfig: false,
    hasFetchedConfig: false,
    config: {
      enabled: false,
    },
    errors: {
      fetchConfig: null,
    },
  },
  reducers: {
    fetchMfaConfigRequest(state) {
      const errors = {
        ...state.errors,
        fetchConfig: null,
      };

      setState(state, {
        isFetchingConfig: true,
        hasFetchedConfig: false,
        errors,
      });
    },
    fetchMfaConfigSuccess(
      state,
      {
        payload: { enabled },
      },
    ) {
      setState(state, {
        isFetchingConfig: false,
        hasFetchedConfig: true,
        config: { enabled },
      });
    },
    fetchMfaConfigError(state, { payload: error }) {
      setState(state, {
        hasFetchedConfig: true,
        errors: {
          ...state.errors,
          fetchConfig: error,
        },
      });
    },
    toggleMfaConfig(
      state,
      {
        payload: { enabled },
      },
    ) {
      setState(state, { config: { enabled } });
    },
  },
});

const { actions, reducer } = mfaSlice;

export const {
  fetchMfaConfigRequest,
  fetchMfaConfigSuccess,
  fetchMfaConfigError,
  toggleMfaConfig,
} = actions;

export default reducer;
