import I18n from '../../i18n';
import {
  backendErrorsArrayIntoString,
  getResponseData,
  handleResponseStatus,
  responseFailed,
  responseSuccess,
} from '../../helpers/requests/bennieFetch';
import { coreClient as client } from '../../helpers/requests/client';

export const getAvailableExports = companyId => {
  return client
    .get(`/v1/admin/companies/${companyId}/availableExports`)
    .then(response => getResponseData(response))
    .catch(error => ({ error }));
};

export const fetchCompanies = async () => {
  const response = await client.get(`/v1/admin/companies?itemsOnPage=100000`);

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error('Could not fetch companies');
  }

  return getResponseData(response);
};

export const fetchUsersInCompany = async ({ companyId }) => {
  const response = await client.get(
    `/v1/admin/companies/${companyId}/impersonationtargetusers?itemsOnPage=1000000`,
  );

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error('Could not fetch users from company');
  }

  return getResponseData(response);
};

export const startImpersonatingUser = targetUserId => {
  return client
    .post(
      `/v1/admin/impersonations`,
      { targetUserId },
      { headers: { Accept: 'application/json' } },
    )
    .then(response => {
      handleResponseStatus(response.status, 400, () =>
        backendErrorsArrayIntoString(
          response,
          ' ',
          I18n.t(`authentication.impersonateDeactivatedUserMessage`),
        ),
      );

      return getResponseData(response);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const getSSOConfig = () => {
  return client
    .get(`/v1/admin/sso/saml`)
    .then(response => getResponseData(response));
};

export const createSSOConfig = ({ idp, file }) => {
  const data = {
    idpType: idp,
    file,
  };

  return client
    .post(
      `/v1/admin/sso/saml`,
      { ...data },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    .then(response => {
      const responseData = getResponseData(response);

      const { status } = response;

      if (responseSuccess(status)) {
        return responseData;
      }

      const errorMessage = responseData?.errors?.join(' ');

      throw new Error(errorMessage);
    });
};

export const deleteSSOConfig = () => {
  return client.delete(`/v1/admin/sso/saml`);
};

export const enableSSOConfig = () => {
  return client.post(`/v1/admin/sso/saml/enable`);
};

export const stopImpersonatingUser = () => {
  return client
    .put(`/v1/admin/impersonations`)
    .then(response => getResponseData(response))
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const resetMFARequest = id => {
  return client.delete(`/v1/admin/users/resetfactors/${id}`).then(response => {
    const { status } = response;

    if (responseFailed(status)) {
      throw new Error('Error resetting user MFA');
    }

    return getResponseData(response);
  });
};

export const enableMFAForCompany = companyId => {
  return client.post(`/v1/admin/companies/${companyId}/mfa`).then(response => {
    const { status } = response;

    if (responseFailed(status)) {
      throw new Error('Error enabling MFA');
    }

    return getResponseData(response);
  });
};

export const disableMFAForCompany = companyId => {
  return client
    .delete(`/v1/admin/companies/${companyId}/mfa`)
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Error disabling MFA');
      }

      return getResponseData(response);
    });
};

export const getMFAConfig = companyId => {
  return client
    .get(`/v1/admin/companies/${companyId}/mfa`)
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Error getting MFA config');
      }

      return getResponseData(response);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const getUserDetails = id => {
  return client.get(`/v1/admin/persons/${id}/user`).then(response => {
    const { status } = response;

    if (responseFailed(status)) {
      throw new Error('Error getting user details');
    }
    return getResponseData(response);
  });
};
