import { createSlice } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import { setState } from '../../helpers/store';
import { SEARCH_TYPES } from '../../constants/findMedicalCare';

const initialState = {
  currentSearchParams: {},
  errorDeletingSavedProvider: null,
  errorFetchingSavedProviders: null,
  errorFetchingSearchResults: null,
  errorSavingProvider: null,
  hasFetchedSavedProviders: false,
  hasFetchedSearchResults: false,

  isFetchingSearchResults: false,
  isFetchingSavedProviders: false,
  isSettingSavedProvider: false,
  savedFacilities: [],
  savedProviders: [],
  searchResults: [],
  searchPagination: {},

  // Provider will probably be fetched and stored inside the component
  // Either way, it's not used at the moment and doesn't have anything to do with appointments
  // appointmentProvider: null,
  // isFetchingProviderResults: false,
  // errorFetchingProvider: null,
  // shouldDeleteProvider: null,
};

const findMedicalCareSlice = createSlice({
  name: 'findMedicalCare',
  initialState,
  reducers: {
    searchRequest: {
      prepare: payload => ({
        payload: {
          ...payload,
          type: payload?.type || SEARCH_TYPES.PROVIDER,
          page: payload?.page || 1,
          insuranceIds: Array.isArray(payload?.insuranceIds)
            ? payload.insuranceIds.join(',')
            : payload.insuranceIds,
        },
      }),
      reducer: (state, { payload }) => {
        const { page } = payload;

        setState(state, {
          isFetchingSearchResults: true,
          searchResults: page > 1 ? state.searchResults : [],
          searchPagination: page > 1 ? state.searchPagination : {},
          currentSearchParams: payload,
          errorFetchingSearchResults: null,
        });
      },
    },
    searchSuccess: (state, { payload }) => {
      const { results, pagination } = payload;
      const page = _get(pagination, 'page', 1);
      const pageSize = _get(pagination, 'pageSize', 20);

      // Put the results at the index corresponding to the page,
      // so first page results are at index 0, third page results are at index 60, etc.
      // Increase array size if necessary
      if (state.searchResults.length < page * pageSize) {
        state.searchResults.length = page * pageSize;
      }
      state.searchResults.splice((page - 1) * pageSize, pageSize, ...results);

      setState(state, {
        isFetchingSearchResults: false,
        hasFetchedSearchResults: true,
        searchPagination: pagination,
      });
    },
    searchError: (state, { payload: { errorFetchingSearchResults } }) => {
      setState(state, {
        isFetchingSearchResults: false,
        errorFetchingSearchResults,
      });
    },
    // fetchProviderRequest(state) {
    //   setState(state, {
    //     isFetchingProviderResults: true,
    //     errorFetchingProvider: null,
    //     shouldDeleteProvider: null,
    //   });
    // },
    // fetchProviderSuccess(
    //   state,
    //   {
    //     payload: { appointmentProvider },
    //   },
    // ) {
    //   setState(state, {
    //     appointmentProvider,
    //     isFetchingProviderResults: false,
    //   });
    // },
    // fetchProviderError(
    //   state,
    //   {
    //     payload: { errorFetchingProvider, shouldDeleteProvider },
    //   },
    // ) {
    //   setState(state, {
    //     shouldDeleteProvider,
    //     errorFetchingProvider,
    //     isFetchingProviderResults: false,
    //   });
    // },
    fetchSavedProvidersRequest(state) {
      setState(state, {
        errorFetchingSavedProviders: null,
        isFetchingSavedProviders: true,
      });
    },
    fetchSavedProvidersSuccess(
      state,
      {
        payload: { savedProviders },
      },
    ) {
      setState(state, {
        savedProviders: _keyBy(savedProviders, 'id'),
        isFetchingSavedProviders: false,
        hasFetchedSavedProviders: true,
      });
    },
    fetchSavedProvidersError(
      state,
      {
        payload: { errorFetchingSavedProviders },
      },
    ) {
      setState(state, {
        errorFetchingSavedProviders,
        isFetchingSavedProviders: false,
      });
    },
    searchProvidersResetFields(state) {
      setState(state, initialState);
    },
    saveProviderRequest(state) {
      setState(state, {
        errorSavingProvider: null,
        isSettingSavedProvider: true,
      });
    },
    saveProviderSuccess(
      state,
      {
        payload: { provider },
      },
    ) {
      setState(state, {
        savedProviders: {
          [provider.id]: provider,
        },
        isSettingSavedProvider: false,
      });
    },
    saveProviderError(
      state,
      {
        payload: { errorSavingProvider },
      },
    ) {
      setState(state, {
        errorSavingProvider,
        isSettingSavedProvider: false,
      });
    },
    deleteFacilityRequest(state) {
      setState(state, {
        errorSavingFacility: null,
        isSettingSavedFacility: true,
      });
    },
    deleteFacilitySuccess(
      state,
      {
        payload: { id },
      },
    ) {
      setState(state, {
        savedFacilities: _omit(state.savedFacilities, id),
        isSettingSavedFacility: false,
      });
    },
    deleteFacilityError(
      state,
      {
        payload: { errorDeletingSavedFacility },
      },
    ) {
      setState(state, {
        errorDeletingSavedFacility,
        isSettingSavedProvider: false,
      });
    },
    deleteProviderRequest(state) {
      setState(state, {
        errorSavingProvider: null,
        isSettingSavedProvider: true,
      });
    },
    deleteProviderSuccess(
      state,
      {
        payload: { id },
      },
    ) {
      setState(state, {
        savedProviders: _omit(state.savedProviders, id),
        isSettingSavedProvider: false,
      });
    },
    deleteProviderError(
      state,
      {
        payload: { errorDeletingSavedProvider },
      },
    ) {
      setState(state, {
        errorDeletingSavedProvider,
        isSettingSavedProvider: false,
      });
    },
    fetchSavedFacilitiesRequest(state) {
      setState(state, {
        errorFetchingSavedFacilities: null,
        isFetchingSavedFacilities: true,
      });
    },
    fetchSavedFacilitiesSuccess(
      state,
      {
        payload: { savedFacilities },
      },
    ) {
      setState(state, {
        savedFacilities: _keyBy(savedFacilities, 'id'),
        isFetchingSavedFacilities: false,
        hasFetchedSavedFacilities: true,
      });
    },
    fetchSavedFacilitiesError(
      state,
      {
        payload: { errorFetchingSavedFacilities },
      },
    ) {
      setState(state, {
        errorFetchingSavedFacilities,
        isFetchingSavedFacilities: false,
      });
    },
    saveFacilityRequest(state) {
      setState(state, {
        errorSavingFacility: null,
        isSettingSavedFacility: true,
      });
    },
    saveFacilitySuccess(
      state,
      {
        payload: { facility },
      },
    ) {
      setState(state, {
        savedProviders: {
          [facility.id]: facility,
        },
        isSettingSavedFacility: false,
      });
    },
    saveFacilityError(
      state,
      {
        payload: { errorSavingFacility },
      },
    ) {
      setState(state, {
        errorSavingFacility,
        isSettingSavedFacility: false,
      });
    },
    fetchFacilityRequest(state) {
      setState(state, {
        isFetchingFacilityResults: true,
        errorFetchingFacility: null,
        shouldDeleteFacility: null,
      });
    },
    fetchFacilitySuccess(
      state,
      {
        payload: { appointmentFacility },
      },
    ) {
      setState(state, {
        appointmentFacility,
        isFetchingFacilityResults: false,
      });
    },
    fetchFacilityError(
      state,
      {
        payload: { errorFetchingFacility, shouldDeleteFacility },
      },
    ) {
      setState(state, {
        shouldDeleteFacility,
        errorFetchingFacility,
        isFetchingFacilityResults: false,
      });
    },
  },
});

const { actions, reducer } = findMedicalCareSlice;

export default reducer;
export const {
  searchRequest,
  searchSuccess,
  searchError,
  deleteFacilityError,
  deleteFacilityRequest,
  deleteFacilitySuccess,
  deleteProviderError,
  deleteProviderRequest,
  deleteProviderSuccess,
  fetchFacilityError,
  fetchFacilityRequest,
  fetchFacilitySuccess,
  fetchProviderError,
  fetchProviderRequest,
  fetchProviderSuccess,
  fetchSavedFacilitiesError,
  fetchSavedFacilitiesRequest,
  fetchSavedFacilitiesSuccess,
  fetchSavedProvidersError,
  fetchSavedProvidersRequest,
  fetchSavedProvidersSuccess,
  saveFacilityError,
  saveFacilityRequest,
  saveFacilitySuccess,
  saveProviderError,
  saveProviderRequest,
  saveProviderSuccess,
  searchProvidersResetFields,
} = actions;
